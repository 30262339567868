@import 'assets/scss/variables.scss';
@import 'photoswipe/style.css';

*{
  font-family:'Poppins', sans-serif;
  color: $primary;
}

@font-face{
  font-family: "Poppins";
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style : normal;
  color:$primary;
}

.mat-snack-bar-container {
  z-index: 10000 !important; 
}

html, body { height: 100%; }
